// import Hero from "../components/Hero";
import Navbar from "../components/Navbar";

import Footer from "../components/Footer";
import Blogs from "../components/Blog";
// import Image13 from "../assets/13.jpg"
// import Destination from "../components/Destination";

function blog() {
  return (
    <>
      <Navbar />
      {/* <Hero
        cName='hero-mid'
        heroImg={Image13}
        // title='Contact'
        // text='We providing you the Services as ....'
        // buttonText='Get in Touch'
        // url='/'
        btnClass='hide'
       />  */}
       
       <Blogs />
      <Footer/>
    </>
  );
}

export default blog;
