import "./FooterStyles.css";


const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div>
          <h1>Athena Automation</h1>
          <p>Always ready to help you.</p>
        </div>
        <div>
          <a href="/">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a href="/">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-instagram-square"></i>
          </a>
          <a href="/">
            <i className="fa-brands fa-twitter-square"></i>
          </a>
        </div>
      </div>

      <div className="bottom">
        <div>
          <h4>Project</h4>
          <a href="/mmsystem">Machine Monitoring System</a>
          <a href="/">Second Project</a>
          
        </div>
        <div>
          <h4>Community</h4>
          
          <a href="/">Issues</a>
          <a href="/">Linkedin</a>
          <a href="/">Twitter</a>
        </div>
        <div>
          <h4>Help</h4>
          <a href="/">Support</a>
          <a href="/">TroubleShooting</a>
          
        </div>
        <div>
          <h4>Others</h4>
          <a href="/">Term of Services</a>
          <a href="/">Privacy Policy</a>
          <a href="/">Lincense</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
