import "./ContactFormStyles.css";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';


function ContactForm() {
  const [message, setMessage] = useState('');

  const handleClick = () => {
    setMessage('Message sent successfully!');
  };
 
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_cvw8blc', 'template_k3227d8', form.current, 'DNI0m_SW9bHpxd2HQ')
        .then((result) => {
            console.log(result.text);
            
        }, (error) => {
            console.log(error.text);
        });
    };
  
  return (

    <div className="from-container">
      <h1>Send a message to us!</h1>

      <form ref={form} onSubmit={sendEmail}>
    
      <input placeholder="Name" type="text" name="user_name" />
  
      <input placeholder="Email" type="email" name="user_email" />
     
      <input placeholder="Subject" type="text" name="user_subject" />
     
      <textarea  placeholder="Message" rows="4" name="message" />
      <button type="submit" onClick={handleClick}> Send Message </button>
      <h2>{message}</h2>
    </form>
      
  

      <hr />

    <div className="contact-info">
       <b>Address: </b> <p>Athena Automation,2, Suryaphool,, 882/B-2, Shivajinagar,, Pune, Maharashtra 411004, IN</p> 
       <b>E-mail: </b> <p>athenaautomation2023@gmail.com</p>  
       <b>Contact No.: </b> <p>9822412220</p> 
       <b>Google Location: </b>   <a href="https://goo.gl/maps/gTU884zeURhPoPdKA"><p>18.51924575419148, 73.83765403665122</p></a>
    </div> 
    </div>
  );
};


export default ContactForm;
