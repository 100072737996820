import "./styles.css";
import Navbar from './components/Navbar'
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Service1 from "./routes/Service1";
import Service2 from "./routes/Service2";
import Contact from "./routes/Contact";
import Blog from "./routes/Blog";
import Mmsystem from "./components/mmsystem.js";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/mmsystem" element={<Mmsystem/>}/>
        <Route path="/Service1" element={<Service1/>}/>
        <Route path="/Service2" element={<Service2/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/blog" element={<Blog/>}/>
      </Routes>
    
      <Navbar />
   
      
    </div>
  );
}
