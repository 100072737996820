import "./video.css";
import sample2Video from "../assets/sample2.mp4";

function video() {
  return (
    <div className="video1">

    <video src={sample2Video} controls="controls" autoplay="false" />

    

      {/* <iframe src={sample2Video}
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowFullScreen
        title='video'
        /> */}
    </div>
  );
}

export default video;
