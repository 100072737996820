// import Destination from "../components/Destination";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
// import Service from "../components/Service1";
import Image13 from "../assets/13.jpg"

function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName='hero'
        heroImg={Image13}
        title='Welcome to Athena Automation'
        text='We providing you the Services as ....'
        buttonText='Get in Touch'
        url='/'
        btnClass='show'
       />
       {/* <Destination/> */}

       <Footer/>
      </>
  );
}

export default Home;
