// import Mountain1 from "../assets/1.jpg";
// import Mountain2 from "../assets/2.jpg";
// import Mountain3 from "../assets/5.jpg";
// import Mountain4 from "../assets/8.jpg";
// import DestinationData from "./BlogData";
import "./BlogStyles.css";
import VideoData from "./VideoData";
// import Video from "./video";
import "./video.css";

const Blog = () => {
  return (
    <>
      <div className="InfoData">
        <h1>Blogs and Videos </h1>

        <VideoData
          className="first-des-reverse"
          heading="Industry 4.0"
          text="Industry 4.0, also known as the fourth industrial revolution, is a term used to describe the current trend of automation and data exchange in manufacturing. It involves the integration of digital technologies such as the Internet of Things (IoT), artificial intelligence (AI), and machine learning to optimize manufacturing processes and create a 'smart factory' where machines and equipment can communicate with each other and make autonomous decisions based on real-time data. Industry 4.0 requires a high degree of connectivity between machines, devices, and systems, enabled by technologies such as 5G wireless networks. It has the potential to transform the way products are designed, manufactured, and delivered, leading to greater efficiency and productivity."
        />
      </div>

      <div className="button">
        <div className="link-div">
          <a href="https://www.youtube.com/watch?v=AjAVdHSbGo0" className="link-btn"> Industry 4.0 Webinar </a>
        </div>
        <div className="link-div">
          <a href="https://www.youtube.com/watch?v=XajcnCWzC6I" className="link-btn"> Presentation for Students </a>
        </div>
        {/* <div className="link-div">
          <a href="https://www.youtube.com/watch?v=CaGMbNEbBvM" className="link-btn"> Watch Video! </a>
        </div> */}
      </div>

      <hr />

      <div className="ArticlesData">
        <h1>Articles on Industry 4.0</h1>

        <h2>Article 1 :</h2>
        <p>Industry 4.0, as a part of the global megatrend digitization, in the

German Interpretation means,

“Manufacturing systems are vertically networked with business
processes within factories and enterprises and horizontally connected to
disperse value networks that can be managed in real time – from the moment
an order is placed right through to outbound logistics.”

This definition of Industry 4.0 alias i4.0, IIoT, is most Important, since
it comes from the people who coined i4.0 as the Fourth Industrial Revolution.
The more prevalent being,

“Integration of Cyber Physical Systems with Humans”, an over
simplified version, I would say, since it is quite fluid. It does Not state the
boundaries or the limits, its left to you to imagine. Since it is related to Industry
in its original concept, I would go by the German Definition.

There are many offshoots to this concept, which is based on IoT or
“Internet of Things” Meaning Internet Connectable Devices (They are just
about appearing in traces in the market) talk to each other, communicate with
each other and take certain decisions without Human Intervention. The Major
Portion of these offshoots being, Home Automation. The fantasy goes to the
level of ‘Your Refrigerator ordering its contents, without your consent, using
your Credit Card, when they fall below a certain level in stocks!’

This does not fall under the i4.0 definition. Though it perfectly fits in
the “Integration of Cyber Physical Systems with Humans”, here we may modify
it to ‘with or without humans!’
A bit about the history of i4.0

Industry 4.0 was coined so, to represent the fourth industrial

revolution.

The Invention of Boiler &amp; Steam Engine is considered as the First

Industrial Revolution.

The Assembly Line &amp; Line Manufacturing is considered as the Second

Industrial Revolution

The Advent of Computers, PLCs and Robots is considered as the Third

Industrial Revolution.

Hence this is Fourth Industrial Revolution aka i4.0.
I would rather Consider Invention of Wheel as the Zeroeth Industrial

Revolution.

I remember the Great Hollywood Actress Audrey Hepburn thanking
Thomas Alva Edison for inventing the Incandescent Lamp, without this
invention the Film Industry wouldn’t have existed.

Similarly without the invention of the wheel the Industry in General
wouldn’t exist! Its morally correct &amp; very much prudent to salute this Invention
First, before we go ahead in the i4.0 details.
Some more details about i4.0

The i4.0 was Visioned for the First time by the Germans. Being an
Industrial Advanced Country. Being Advanced has its own drawbacks. You are
plagued with Very High Wages. Resulting in Costly Products. This results in
everyone having overseas operation in Low Cost countries like China, Brazil
and India. The problem is the Jobs in the parent country are lost.

The only way to retain those jobs is by increasing the productivity.
Humans can’t be productive beyond a limit. So Robots appeared on the scene,
now when they are also waiting for maybe some decisions or availability of
material or machine programs or tooling or whatever, you are back to loosing
the productivity.

i4.0 is an endeavour to digitize all possible information, available in
an enterprise to have some processes automated without human intervention.
It also gives enterprises to analyse the huge amounts of data made available
for programs like Preventive &amp; Predictive Maintenance. Machine OEE. Finding
New Requirements of machines. Making changes in the product for better
performance. Modifications of machines for production and many more.

i4.0 is NOT Just a Buzz Word. It’s going to be there. It’s going to grow,
since if you don’t adopt it your Productivity Levels are NOT going to Increase to
the levels of those adopting it. Million Dollar Question is WHAT would be the
pace of the unfurling of this phenomenon called i4.0?</p>

        <h2>Article 2 :</h2>
        <p>In my last article on Industry4.0, I stated its Definition, talked

something History, &amp; an overall review of the concept of i4.0.

As discussed in the last article Industry 4.0 is, “Manufacturing
systems are vertically networked with business processes within factories and
enterprises and horizontally connected to disperse value networks that can be
managed in real time – from the moment an order is placed right through to
outbound logistics.”, as defined by the Germans.

This definition talks of both horizontal &amp; vertical integration of
processes across different business entities, from Order Placement to
Shipment Logistics. It’s a huge scope!

Imagine car manufacturing. The moment you get an order the order
details are supposed to be automatically split and despatched to every
supplier, from sheet metal supplier to the digital engine controller supplier.
Here the ERP may intervene &amp; club the orders to each supplier as per the
decided norms, but it’s supposed to happen automatically at an appropriate
time after scheduling the actual assembly of the car on the conveyor line. A
shop order is released for manufacturing of machined parts &amp; an assembly
schedule is released. The machines are scheduled accordingly. The raw
material is supposed to arrive just in time &amp; the machine is readied earlier by
the system for machining of the parts. The ASRS (Automated Storage &amp;
Retrieval System) loads the AGVs (Auto Guided Vehicles) to deliver the raw
material &amp; tooling to the robots setting up the machine! Gosh! And so many
more things happening till the car is delivered to the customer. And all this
happens for each component being machined! Just imagine the complexity.
The scope is enormous, hence there are many more technologies
than just M2M (Machine to Machine) communication involved in the process.
This is as Fantasized as the refrigerator example in the last article.

Technologies like, Big Data Analysis, Artificial Learning, Auto
Navigation of AGVs and Drones for delivery and host of other technologies.
Without these and many more technologies it is not possible to Digitize &amp;
Integrate everything together.

But before that there is One Thing that we must understand, and
that is, Each Process involved must be robust! Otherwise the garbage in

garbage out, saying in a typical IT industry, will have to be modified to, Good
Raw Material in Garbage out! For Manufacturing industry. This is a foremost
requirement in any Automation Project for that matter, and i4.0 endeavours to
automate end to end processes as its ultimate goal.

This requirement is even today fulfilled by almost all automobile
manufacturers. That is essential for them, when they produce millions of
vehicles in a year. If they don’t do it they would be doomed!

It obviously means that Automobile Manufacturers are in a Mostly
Ready to implement i4.0 in a very short term to quite a significant level.
Engineering industry on the other hand, where the volumes are not
as high, really first need to “Look Into” the Process of making their
Manufacturing Processes Robust &amp; Fool Proof. There are many ways to do it.
Some of them include techniques like POKA-YOKE or mistake proofing,
introduction of Automated Inspection at each stage and In My Opinion, the
most ignored aspect in engineering industry, Standardization.

It’s been in the DNA of Auto Industry, remember the famous quote
of Henry Ford, Any colour is OK, till it is Black. Standardization is something
mastered by the Auto Industry. Now they want to De-Standardize and do
Custom builds for their Customers using i4.0. But I am quite sure they are
aware that process for Each Custom Designed Part shall be made robust.
Robust Process is again an integral part of Auto Industry DNA

It is obvious that it is impossible to do everything in a single go! Each
enterprise has some strengths and weaknesses of its own. It’s upto each
organization to decide where to start, how much to endeavour, and when to
move to the next phase of implementation especially in the Indian
Manufacturing Scenario.

To Conclude, The i4.0 is going to take some time to unfold
completely. If an organization wants to implement i4.0 (I don’t think there is
much choice here) first they will have to make All their processes Robust and
Reliable. The implementation will have to be done in a well thought out
manner, taking into consideration their own strengths &amp; weaknesses &amp; priority
areas.</p>

        <h2>Article 3 :</h2>
        <p>In my last article on Industry 4.0, I talked more about necessity of
having robust processes as prime requirement and why auto industry is more
ready for adapting Industry 4.0 because of it&#39;s basic nature. Today let us talk
about Discrete Manufacturing and Industry 4.0.

When it comes to SME&#39;s in engineering sector, most of them have
discrete manufacturing as their strength. Manufacturing a variety of
components with variety of operations concurrently is their strength. They
have a great variety of machines which can produce these components with
the large bouquet of processes that they have expertise in most of them being
more of technocrats than businessmen.

Having said that, the internal management of the operations is a
mammoth task. Management they say is an Art of achieving more than what
Science says is possible!

You have nowadays ERP &amp; MRP and all the software tools which can
give you the figures that the Science says is possible, since it&#39;s all based on
mathematics and logical deduction from the available data regarding the
features like Process times, Process cost, Material cost, Lead time to get raw
material and many more things.

The Art part comes into play in managing the conversion of raw
material to finished goods. Which is entirely an internal process. Now here the
trio of Productivity, Quality, and Machine availability, that comprise the Overall
Equipment Effectiveness the OEE, have to be managed effectively. Productivity
is one factor dependant entirely on the operators. Quality issues crop up due
to poor Process robustness as well as problematic Inspection processes. and
Machine availability issues due to innumerable factors from Choice of
machine, to it&#39;s Capability to handle the Job, to available Capacity, to the
Seriousness in it&#39;s use and maintenance. They all play truant resulting in
spoiling of the End Results.

To compound the issue, the Management Processes on the Shop-
floor are controlled and managed by people called Managers. They along with
managing the shop operations are also capable of Managing reporting of
Numbers! I have worked on shop-floor all my life believe my they have to do it
even if they do not wish to do so. Resulting in subprime results in numbers!

What Industry 4.0 does is it acts like a King Harishchandra from
Indian mythology the epitome of truthfulness, and blurts out Exact and
Accurate numbers in Real-time. Scary situation for shop ops!

A little about, how is it done. It&#39;s done by putting sensors for
measuring crucial parameters like Production count, Machine run time,
Operator details, Rejection and Rework count with reasons, one is forced to
give reasons, else the machine won&#39;t start again! and many more of such
things, resulting in Extreme transparency in operations

Like the ERP took away the inaccuracies in Non shop areas of
Management by the way of automation by placing suitable Validations and
Placing interlocks, Industry 4.0 automates the reporting process on the shop
floor. Leaving the Shop Ops with no other option to be honest &amp; truthful!
Again the Process is same of Proper validation of data and Appropriate
interlocks!

A word of caution here, this can culminate in spoilt Industrial
Relations and opposition from the Labour Unions! Majority of work force is
workers!

Industry 4.0 in my opinion, is more of Change management than
Technology adoption. Every Worker on the shop floor &amp; shop managers, must
be taken into confidence, Pre-counselled for the Impending change in their
ways of working should be done, and keep them mentally prepared for it. That
in my opinion is the first step in implementing Industry 4.0 in discrete
manufacturing setup. In sectors like Auto Industry, work measurement is very
rigid hence Adoption of Industry 4.0 is comparatively easy as compared to
discrete manufacturing setup.

No wonder then the Industry 4.0 initiative Has to be Initiated,
Managed and Wholeheartedly supported by the Top management. Without
Top management Support Industry 4.0 is extremely difficult to implement in
Discrete manufacturing setups.

To Conclude, The i4.0 implementation in Discrete manufacturing
setups is more of Change management exercise than Technology
management. It&#39;s more of preparing the shop ops to accept the change. The
technology can be handled by the vendors! The dividends of implementation
of Industry 4.0 can be rich If Implemented properly. Will be discussing more
about Industry 4.0 basics before moving to, how to Implement in the following
articles.</p>

        <h2>Article 4 :</h2>
        <p>In my last article on Industry 4.0, I talked about implementing or
adapting Industry 4.0 in discrete manufacturing. In this article let&#39;s talk about
implementing Industry 4.0 in process industry, where the industry runs 24 x 7.
This is another peculiar industry type. This includes mostly chemical
industries which run 24 x 7. To give an example, say petroleum refineries or
urea manufacturing plants, electrical power plants, and the similar type of
industry, where the plant runs continuously for the whole year. They take a
maintenance break once in a year or two to revamp the whole plant and
restore it to the mint condition.

These kind of plants typically have certain characteristics,
redundancy is in built for most of the support equipments, other than the main
reactor columns / equipment. These support equipments are usually sourced
from the world class suppliers, though that may be costly proposition, it proves
to be cheaper in the long run, since the breakdown or stoppage of the entire
plant because of support equipment failure, can cost way too much! In many
cases restarting the plant takes days if not weeks!

There is another peculiarity of these types of plants, they are usually
fully automated. They have a control room from where the complete
operations of the plant are monitored. It is run by technicians and not
operators or workers, since there is no manual activity involved. The control
room is from where the whole plant is run. workers are there only for the
maintenance activity of replacing / repairing faulty equipment, once the
controller shifts it&#39;s function to the redundant piece of equipment either
automatically or by manual intervention.

This type of industry is most suited to implement Industry 4.0. In fact
it is very close to Industry 4.0 implementation. Most of the production data is
readily available. Major form of data that you need to capture is regarding the
equipment condition. You may have to install sensors like vibration sensors for
various pumps, compressors, turbines, and other rotary equipments, to
monitor condition of bearings. Many other types of sensors like pressure, level,
flow and many more can be installed. This is required for predictive
maintenance activity. Predictive maintenance is the major advantage that can
be reaped in such setups, since it is of prime importance that the plant remains
in working condition 24 x 7 for months together. Good predictive
maintenance is an area which pays off the investment in implementing
Industry 4,0 in such plants. It&#39;s the major contributor for ROI calculations also.
Predictive maintenance requires Analytics to be performed on the data

collected from the various equipment, this can be either done locally or
through cloud computing. It can further be used for optimising the critical as
well as non critical parts inventory for various equipments required to function
all the time till the planned shutdown for maintenance is implemented.
Apart from this major area Industry 4.0 can be used to monitor
movement of men &amp; material through the plant. It can also be used for asset
management function. Tracking of repairs and replacement of equipment
becomes possible. If the data is properly analysed, it can also be used for
equipment standardisation and substitution program.

These plants usually have some sort of a bigger version of SCADA. In
conjunction with this SCADA like system, when Industry 4.0 is implemented
some of the mundane or intruding tasks of people manning the control room
like reporting of unusual incidences e.g. some equipment failures and shifting
of equipments to standby equipment etc can be easily automated. Industry 4.0
implementation can send SMSs or even emails to the right people in that point
in time. The right people are made aware of the ground reality in real time. In
case of accidents or fires, the alarm can be set to go off in the fire fighting
equipment room, with location and other details like equipment id etc can be
sent automatically through SMS to the concerned people. It is comparatively
easy to implement Industry 4.0 in such plants. It is beneficial &amp; easy to
maintain the plant in running condition, if Industry 4.0 is implemented.
I am sure the people actually working in such plants can come up
with many more things to implement through Industry 4.0 hardware &amp; can
help in customising the software for their plant. Domain experts always play a
major role in implementation of such projects in any type of industry, not just
process industry. The critical knowledge of the plant and processes is available
with them and their inputs are most important in implementing such projects.
To Conclude, The Industry 4.0 implementation in Process Industry is
comparatively easy, since it is already automated to quite a great extent. The
main benefit is accrued in implementing the predictive maintenance function.
This requires some analytics to be implemented before you can reap the real
tangible benefits. Many other intruding tasks can be automated and all the
right people can always remain updated in real time even if they are located
remotely. In the next few articles let me delve a bit deeper not too deep, but
to cover absolute basic details of technology &amp; Why Industry 4.0 now!</p>

        <h2>Article 5 :</h2>
        <p>In my last article on Industry 4.0, I talked about implementing or
adapting Industry 4.0 in process industry. In this article I am going to write
something about my plan of continuing writing these articles. And what would
they contain henceforth. Obviously they shall be on Industry 4.0. And they will
be written with discrete manufacturing as a basic point of reference.

I realised after writing four articles that, I need to go deeper in the
subject. Till now it has been a surface skimming kind of presentation. A very
basic introduction of the subject, but if one has to really understand the
subject, I need to go deeper.

So I am planning to write roughly 50 articles on this subject, at a rate
of roughly three in a month. The intension is when collated together they
should form a complete and concise guide for anyone who wants to
understand the subject thoroughly.

Thoroughly enough to be able to make decisions if it&#39;s Good /
Required for their organisation. To be able to talk to the concerned project
vendors who would be executing the project, in a convincing manner about
their exact expectations. To be able to understand the technical nuances
about the subject that really matter, and ensure they are addressed while
implementation planning and execution. Understand the slang of the subject,
it&#39;s typical essential components, like &#39;Edge Node Device&#39; or &#39;Gateway&#39; and the
similar. In general to be thoroughly comfortable about the subject. To be able
to make informed statements when in conversation with colleagues or peers.
To be able to convince customers as to how the Industry 4.0 is helping you
serve them better, without making any false claims! To be able to be confident
about all of the above.

The approach that I am planning is to divide the articles, initial 30
articles, roughly will be about the core subject, while the remaining will cover
the supporting technologies that are not part of the core Industry 4.0, but they
augment it, by complimenting the Industry 4.0 effectiveness by their use.
These article will also cover it&#39;s effects on industry, society, economy,
employment front, etc..

I believe, that there are two approaches for learning anything with a
finite defined content in it, while working or while learning it on the job.
The first approach is jig saw puzzle approach. Here you just attack
any problem that crops up head on. You learn something. This is like putting a

piece in a jig saw puzzle in it&#39;s rightful place, with or without any of it&#39;s
neighbours around. And with most of them in place after a while, in due course
the complete picture is clear to you. This probably was the standard method in
the olden days! In an organization this usually happens when there is no
standard training program present or when you are trying to learn something
on your own without any external help. Also it is the only method available for
self learning people.

The second approach is more structured, like shooting a panorama
picture. I do a lot of panorama picture shooting. During this, we take many
overlapping pictures in a specified sequence. The big picture is a stitched
version of these pictures. It is done in a sequence of a horizontal sweep
followed by a vertical shift, again a horizontal sweep and so on. Each of the
vertical shift is with a slight difference in their orientation or point of view.
I am going to follow the second approach, where I will take a dig at
one aspect followed by a second sweep with a different aspect and so on. I will
be starting with technology, then how to implement and then probably the
remaining things will follow. So it could be like a three or four sweep
panorama shoot that I am planning the whole exercise to be like. I am hoping
that this will lead to a structured approach to cover the complete spectrum of
Industry 4.0 in a concise but comprehensive manner, to serve the intension of
writing it.

There is also one selfish motive in doing this exercise, it makes me
work on the subject. Makes me learn the subject in more detail. Gives me
confidence in my own ability to learn the subject in a jig saw puzzle way, but be
able to put it forth in a panorama shoot way, if I may say so! I will try to make
things as simple as possible for anybody to understand the core without
going deep in the technology. In a nutshell I will try to cover the 5W1H of
Industry 4.0 without going too deep, but touching all the concepts.

To Conclude, This article was more of a Preface to what I intend to
write. The basic intension of this whole exercise is to make the subject of
Industry 4.0 simple to understand to anyone from a student to a decision
maker, the Soul of the subject without getting too much into the technology.
To be able to consider all critical issues and challenges in technology and
implementation. To be able to ensure they are adequately addressed. And give
readers a complete true, practical, and comprehensive picture of Industry 4.0.

Wish me Good luck! Hope it serves it&#39;s intent.</p>

        <h2>Article 6 :</h2>
        <p>In my last article on Industry 4.0, I talked how I am planning to move
ahead! So Let&#39;s reboot and move back to the history of Industrial revolutions
from beginning at a slower pace with detailed understanding of significance
each of them. It will help us understand how things progressed right from
beginning.
I think even before the first industrial revolution, the invention of a
wheel is more significant. It is what gave the humankind a sense of continuity,
confined in a finite object. A wheel can retrace its periphery continuously as it
rotates around it&#39;s own axis, this was something of a revelation in those days!
At the same time it was practically possible to craft the wheels out of stone or
wood. I would tend to call this the zeroeth industrial revolution.
The first industrial revolution was a technical leap. It was a revolution,
because now you could run a carriage or a stagecoach without horses! This
was the stage where man could harness mechanical power without the help of
animals. This is signified by invention of steam engine. This opened several
opportunities for mobility and industrialisation in general. In this stage the
industries would have a big central boiler which would supply steam to a big
steam engine which would run all the machines in a factory using a central
feeder shaft, with rapid engagement and disengagement belt mechanism to
activate a particular machine. When I was small, I remember of having seen
this mechanism, just that the steam engine was replaced by a huge electric
motor, which would run continuously. I come from a remote region of
Maharashtra state and this technology was still in use in those kind of remote
places even in the late 1960s. Though this was first used sometimes in
between late 1700s and early 1800s! This was also the time when cotton
processing, spinning, and cloth weaving machines were invented and cloth
making became an industry. This was also the time when the early railways
started operating. All this happened mainly in the United Kingdom, the rest of
the Europe, and the United States of America to some degree. Till this point in
time it was mostly Asia, that was leading in producing goods. It were the
artisans and craftsmen who did it with their skills, which were honed and
passed on from one generation to another, in a on the job training kind of a
mode, and the skill was all that was extremely important. No power was used
by these people. This method is still in use in learning music in a guru-shishya
parampara, music is still an art!

The second industrial revolution was mostly, advancements in
operational management rather than technology, though aptly supported by
relevant technology. This was the time when Standardisation, Specification
and Simplification, the 3&quot;S&quot; of operational management were born. The
testimony to this is the famous quote of Mr Henry Ford of Ford Motor
Company, &quot;You can have it in any colour you want, as long as it is black.&quot; Ford
Model T was the first mass produced car in this world. It looked more like a
horse carriage than a modern day car. Though petrol engine was invented in
Europe, Ford Motor Company was the first to mass produce a car on
commercial basis. This was the time when conveyor line system of assembly
was put in place. This is the time when electricity was born and electric
motors came into existence. The machines gradually started becoming free
from central power shaft mechanism of earlier times, making it easy to layout
the factory floor for a product flow. This happened sometime between late
1800s to early 1900s. This was the time when we saw great inventors and
scientists like Nikola Tesla, Thomas Edison, Karl Benz, Albert Einstein,
Alexander Graham Bell and many more. This was the time when Physics and
Chemistry bloomed and proliferated. This was also the time when Steel making
became a big industry. During this time a great visionary in India Sir Dorabji
Tata formed Tata Iron and Steel Company, which started it&#39;s operations in
1907. The power plants producing electricity were established in this same
period. All of them were thermal power plants. This also resulted in wide
spread progress in mining of coal and iron ore. I guess this was the time from
when the economy took off. The integration of world economy actually
started with Bretton Woods Agreement signed by 44 countries in July 1944.
This coincides with the later part of second industrial revolution. It was
essential for the growth of industry to have world economy to integrate. This
marks the birth of Macro Economics. The Industry and Economy both grew
exponentially from this point, but I think this is also the moment from
when man started abusing the nature and environment, and we are now
noticing after almost a century the effects of this! Now you can see the effects
in the form of unnatural levels of Ozone, Carbon dioxide, normal atmospheric
temperatures around the globe, and many more.
To Conclude, The first industrial revolution actually precedes with a
zeroeth industrial in the form of invention of a wheel. the first industrial
revolution is signified by a invention of an artificial source of power. While the
second industrial revolution is more of advancements in operations
management, along with integration of world economy, so also abuse of
nature and environment, as side effects! Let&#39;s continue from here in next
article.</p>

        <h2>Article 7 :</h2>
        <p>In my last article on Industry 4.0, I talked about the history of first
and the second industrial revolutions. In this article let us talk about the third
industrial revolution and how the seed of the fourth industrial revolution was
sown right in the third industrial revolution itself.

The third industrial revolution began with the advancement in digital
electronics. Electronics per say was there already existing. The invention of
radio transmission and telephony are the proof of that! It was all analogue
electronics. It used gas valve like diodes, triodes, pentodes &amp; heptodes in
advanced equipments of those days. These were glass tubes with various coils
enclosed in a sealed glass capsule with gas filled inside to conduct the signal in
the form of ions between the electrodes as desired. The connections for
various coils were taken out of the tube before sealing it. There used to be a
heater coil for heating the gas inside to facilitate ionisation of the gas. These
devices were the equivalent of today&#39;s semiconductor transistor. These devices
were used in the radio sets used by our grandfathers! They would take over a
minute to heat up the gas inside the valves before they could start functioning.
The oldest of the computers were mechanical computers made by
great people like Blaise Pascal in late 1600s. These later on graduated to Slide
rules in the west and similar attempt in the east resulted in the invention of
Abacus! The slide rules work on the principle of Logarithms, is all that I know
about it, I personally have never used them, though I own a couple of them!
The early electronic computers also used gas valves!

All this changed with the invention of semiconductors. The gas tubes
got replaced by semiconductor transistors, still essentially analogue devices.
During the times of the second industrial revolution George Boole had
invented Boolian Logic in mid 1800s. With combination of the semiconductor
transistors and Boolian Logic the Logic Gates were devised. This paved the way
for Digital Electronics and an exponential growth in computer technology. This
further got accelerated with the invention of Micro Processors.

A little lagging was the progress of use of microprocessors for
industrial controls, both computers and industrial controls though have similar
processing requirements, the industrial controllers have to interact directly
with the industrial hardware instead of the standard I/O devices used by the
computers. The industrial controllers also have to be more flexible and robust
in nature regarding it&#39;s capacity in terms of handling number of industrial
hardware, to match the requirements of different equipments. Also it is

essential that they have the capability to add to the above mentioned capacity
and work in unison with more than one such units.

Yes, I am talking about PLCs &amp; CNCs. PLCs are comparatively simpler
devices, but CNCs are far more complicated pieces. It actually is a combination
of a power supply unit, a main controller, which controls the whole unit, servo
drives, for servo motors for all the axes and spindle motor, which is also a
servo motor, all the servo motors and spindle motor are also integral part of
the CNC unit. In olden days, all this used to be bundled in a single unit, with
just motors as separate pieces. In modern controllers, they are all separate
units communicating with each other using some protocol. The CNC is
designed to create complicated shapes. That is possible if and only if multiple
axes &amp; spindle(S) work in coordination, and in synch, after overcoming the
inertia of the entire machine mechanicals too. Not an easy task at all!

Obviously this necessitates that the communication protocol
communicating between different units of CNC must have extremely low
latency. During the development of industrial control systems researchers
found that the existing Ethernet protocol, prevalent in the computers was not
suitable, because of very large latency in communication, for this application
though it is good enough for IT systems even today! So every company in
absence of any standard, set on to create their own industrial protocol.
Fortunately and probably just by sheer luck or fluke, they all modified some
layers of Ethernet protocol so all of them still use, so to say some slang of
Ethernet. But they are neither same within themselves nor same as Ethernet.
Some conversion is essential. Precisely the reason why the machines can&#39;t be
just simply connected to IT systems or to each other for communication.
This is the prime reason why a fourth industrial revolution took so
long to come. Another reason why it is happening now is the great
advancements that have taken place in the sensors technology, and quantum
rise computing power at lower costs and lower levels of energy consumption.
To Conclude, The third industrial revolution is signified by the
advancement in digital electronics, computing &amp; control technologies. The
standard Ethernet protocol from the computing side which was ahead of
industrial controls is not suitable for industrial controls, because of it&#39;s latency.
Industrial control use proprietary protocols, derived by different companies, to
overcome this problem of high latency. All of them are some modifications of
basic Ethernet protocol. This and advancements in sensor technology and
computing speed at low cost have facilitated the fourth industrial revolution.</p>

        <h2>Article 8 :</h2>
        <p>In my last article on Industry 4.0, I talked about the history of the
third industrial revolution. In this article let us talk about the fourth industrial
revolution and start moving in more details of the same. From it&#39;s evolution,
timing, expectations, implementation, possible after effects, on industry,
economy, society and in general we as humans.

The &quot;Fourth Industrial Revolution&quot;! &quot;i4.0&quot;! &quot;Industrie4.0&quot;! and it&#39;s
many clones formed by the adoption of the original German concept, with
tweaks with specific reference to different countries, like &quot;Make in India&quot; with
specific reference to Indian environment, her culture, her needs, her socio-
political scenario, her economic demography, and each country having it&#39;s own
modified / aligned version, is just about to take off in a big way! This has got
many angles to it. Many view points to it. From commercial to technical to
economic, to psychological to ecological to intellectual to mention a few. It is
all encompassing. Here is a link to a very nice video on this by World Economic
Forum. <a href="https://www.youtube.com/watch?v=kpW9JcWxKq0">https://www.youtube.com/watch?v=kpW9JcWxKq0</a> .

It gives us a fair idea as to what is the length and width of the whole
gamut of fourth industrial revolution. Fourth industrial revolution will be
changing the human behaviour significantly. From change in the nature of jobs
that will be there to how we interact with various pieces of equipment around
us. Even as of today UI &amp; UX, the &quot;User Interface&quot; &amp; &quot;User Experience&quot; design
is a complete subject in itself! Every equipment is going to become more
human-centric. The crux or the gist of the fourth industrial revolution is the
synergy between cyber-physical systems and humans. So it is natural that
everything will be human-centric.

If we look at the history of evolution of equipment design, we can
see that the early designs were purely functional. From the Ford T model,
which looked more like a horse drawn cart without a horse, than a modern car,
or the typewriter, with a quirky QWERTY keyboard, designed so to avoid the
hammer heads getting entangled, by carefully studying the frequency of use of
the alphabets and distributing them in a fashion that will do the intended job
flawlessly, or for that matter the tuk-tuk or the old auto rickshaws designs, are
all highly functional designs, those of you who are old enough and remember
the early design of auto rickshaws, it was literally like the jugad that you still
see in north India! a proper scooter-like driver seat with an unsupported
canopy protruding from the passenger cabin to protect the driver!

Gradually it evolved and we got the element of, ergonomics, then
aesthetics, then safety, and now user interface and user experience in the

equipment design. Actually if you look at it, it&#39;s evolution and refinement in
ergonomics! with every user taken into consideration. And when you look it
through industry 4.0 lens, the next would be predictive maintenance, and
remote / autonomous operation! Not to forget connectivity!

Connectivity is pre-requisite for the next level of evolution of the
design of any equipment. That is the equipment has to be net-enabled. That&#39;s
&quot;Internet of Things&quot;! Connecting every piece of equipment to internet is the
crux or gist or the core of industry 4.0. That is when the equipment and
humans are so to say bound to each-other by internet. A confluence of Cyber-
Physical-Humans. Humans and equipment need not be in proximity of each-
other to be able to interact. Humans could monitor or for that even run the net
enabled equipment sitting anywhere in the world, till there is net connectivity.
Now we know why 5G technology is so much important.

In very simple terms it is a conduit with a extremely high capacity
and extremely low transfer resistance for carrying the data from one point to
another. To give analogy shifting from 4G to 5G is like getting a bigger water
connection from municipal corporation. Say a 4&quot;dia pipe instead of 1/2&quot; pipe.
Now you are able to have as many taps as you wish! Now the connecting
equipment will also have to have capability to connect to 4&quot;pipe, and that&#39;s
equivalent to have 5G enabled device / equipment!

If we go back, in hindu mythology, we find this character called
Sanjay who would sit next to Dhrutarashtra who was blind king of Indraprastha
and narrate him what is happening in the war being fought between Kaurawas
&amp; Pandawas in a place called Kurukhetra, a few hundred kilometers away! He
was supposed to have Divyadrishti, divine sight and could have a look at what
is happening anywhere in the world. At a more practical level it was probably
Apollo 11 that used this remote sensing &amp; control, the earliest use of real
practical working connectivity!

To Conclude, The fourth industrial revolution is connecting physical
assets and equipments to humans or in many cases computers, capable of
taking decisions autonomously, having some level of intelligence built into
them. The idea is connecting them to each-other, into a huge network akin to a
really big group on WhattsApp, where each individual may not communicate
with every other, but can get in touch with any member, whenever needed.
There is a wonderful protocol which does this. MQTT. We will be discussing
this in due course, when we discuss protocols in detail.</p>

        <h2>Article 9 :</h2>
        <p>In my last article on Industry 4.0, I talked about the history of the
third industrial revolution. In this article let us talk about the fourth industrial
revolution and start moving with more details about the same. From it&#39;s
evolution, timing, expectations, implementation, possible after effects, on
industry, economy, society and in general we as humans.

To Conclude, The fourth industrial revolution is connecting physical
assets and equipments to humans or in many cases computers, capable of
taking decisions autonomously, with some level of intelligence built into them.
The idea is connecting them to each-other, into a huge network akin to a really
big WhattsApp group, where each individual may not communicate with every
other, but can get in touch with any member, whenever needed. There is a
wonderful protocol which does this. MQTT. In fact WhattsApp and Facebook
Messenger use MQTT protocol to deliver messages! Will discuss MQTT later!</p>


      </div>




     
    </>
  );
};

export default Blog;
