import "./ServiceStyles1.css";
// import ServiceData1 from "./ServiceData1";
// import Service1 from "../assets/5.jpg";
// import Service2 from "../assets/8.jpg";
// import Service3 from "../assets/6.jpg";
import productionHeight from "../assets/productionHeight.png"
import systemConfiguration1 from "../assets/systemConfiguration1.png"
import systemConfiguration2 from "../assets/systemConfiguration2.png"

function Services1() {
  return (
    <div className="Service">
      {/* <h1>More About Our Services</h1>
      <h2>We provide Services to you like ...</h2> */}

      <h1>Production Height</h1>
      <img src={productionHeight} alt="productionHeight" />

      <h1>System Configuration</h1>
      <img src={systemConfiguration1} alt="systemConfiguration1" /> <br /> <br />
      <img src={systemConfiguration2} alt="systemConfiguration2" />
      
      {/* <div className="Servicecard">
        <ServiceData1
        image={Service1}
        heading="First Service"
        text="ipsum dolor sit amet consectetur adipisicing elit. Autem optio minus excepturi porro voluptatibus at, blanditiis unde ad. Alias quibusdam quisquam asperiores nobis cupiditate rem delectus facilis rerum deserunt. Vero aperiam error molestias et at, blanditiis, ab quod quae facere sunt iusto voluptatem debitis neque atque fugit dignissimos laborum! Labore sequi accusantium, debitis vitae officiis accusamus esse quisquam volupt"
        />
        <ServiceData1
        image={Service2}
        heading="Second Service"
        text="ipsum dolor sit amet consectetur adipisicing elit. Autem optio minus excepturi porro voluptatibus at, blanditiis unde ad. Alias quibusdam quisquam asperiores nobis cupiditate rem delectus facilis rerum deserunt. Vero aperiam error molestias et at, blanditiis, ab quod quae facere sunt iusto voluptatem debitis neque atque fugit dignissimos laborum! Labore sequi accusantium, debitis vitae officiis accusamus esse quisquam volupt"
        />
        <ServiceData1
        image={Service3}
        heading="Third Service"
        text="ipsum dolor sit amet consectetur adipisicing elit. Autem optio minus excepturi porro voluptatibus at, blanditiis unde ad. Alias quibusdam quisquam asperiores nobis cupiditate rem delectus facilis rerum deserunt. Vero aperiam error molestias et at, blanditiis, ab quod quae facere sunt iusto voluptatem debitis neque atque fugit dignissimos laborum! Labore sequi accusantium, debitis vitae officiis accusamus esse quisquam volupt"
        />
      </div> */}
    </div>
  );
}

export default Services1;
