import "./ServiceStyles2.css";
// import ServiceData2 from "./ServiceData2";
// import Service1 from "../assets/5.jpg";
// import Service2 from "../assets/8.jpg";
// import Service3 from "../assets/6.jpg";

function Services2() {
  return (
    <div className="Service">
      {/* <h1>More About Our Services</h1>
      <h2>We provide Services to you like ...</h2> */}


      <h1>Four Basic Reasons, you should go in for Gates made by Athena Automation</h1>

      <br /><br />
      

      <h2>Safety & User Convenience.</h2>

      <p>
        Over Current and Short Circuit Protection as a Standard Feature available.  <br />
        Redundant Sensors for safety & continuity of operation. <br />
        Fault Indication in PLC.  <br />
        Retro-reflective sensor used for safety during operation.  <br />
        All Components used are CE Marked. <br />
        Water proof Operating Switch Housing. <br />
        Gate will close Automatically when the supply is reestablished, if broken during operation <br /> <br />

        Manual Operation in case of electricity failure is possible. <br />
        Partial Opening is Possible, avoiding a Wicket Gate. <br />

      </p>

      <br /> <br />

      <h2>Reliability & Dependability because of Superior Technical Specifications.</h2>

      <p>Proven Industrial Grade PLC Controller. <br />
        Industrial Grade Electrical Switch Gear. <br />
        Easily Replaceable Standard Electrical Components. <br />
        Crane Duty Three Phase Motor. <br />
        Heavy Duty Rack and Pinion Arrangement. <br />
        All Mechanical Parts, designed with Optimum Capacity bearings. <br />
        All Mechanical Parts closer to the ground have Rubber Seals. <br />
        Cradle Type Wheel assembly for Smooth Working for Heavy Duty Application  <br />
        (Upto 2 tons of Gate Weight).  <br />
        <br />
      </p>
      <br /> <br />

      <h2>Availability of Numerous Accessories.</h2>

      <p>Self Diagnostics for All Sensors used with SMS indication <br />
        Remote Locking using Wi-Fi <br />
        Remote Operation Using SMS <br />
        Pre intimation for Gate Servicing based on Load to operate <br />
        Siren during operation <br />
        Can be operated from outside using RFID Tags <br />
        Video Recording During Operation <br />
        Follow me Home Lights <br />
        Automatic Operation of Lights at Gate depending on Light Condition <br />
        Fast Tag type operation for Company / Society Vehicles <br /> <br />

        Any Accessory Can be added Anytime Later if required. <br />
      </p>

      {/* <div className="Servicecard">
        <ServiceData2
        image={Service1}
        heading="First Service"
        text="ipsum dolor sit amet consectetur adipisicing elit. Autem optio minus excepturi porro voluptatibus at, blanditiis unde ad. Alias quibusdam quisquam asperiores nobis cupiditate rem delectus facilis rerum deserunt. Vero aperiam error molestias et at, blanditiis, ab quod quae facere sunt iusto voluptatem debitis neque atque fugit dignissimos laborum! Labore sequi accusantium, debitis vitae officiis accusamus esse quisquam volupt"
        />
        <ServiceData2
        image={Service2}
        heading="Second Service"
        text="ipsum dolor sit amet consectetur adipisicing elit. Autem optio minus excepturi porro voluptatibus at, blanditiis unde ad. Alias quibusdam quisquam asperiores nobis cupiditate rem delectus facilis rerum deserunt. Vero aperiam error molestias et at, blanditiis, ab quod quae facere sunt iusto voluptatem debitis neque atque fugit dignissimos laborum! Labore sequi accusantium, debitis vitae officiis accusamus esse quisquam volupt"
        />
        <ServiceData2
        image={Service3}
        heading="Third Service"
        text="ipsum dolor sit amet consectetur adipisicing elit. Autem optio minus excepturi porro voluptatibus at, blanditiis unde ad. Alias quibusdam quisquam asperiores nobis cupiditate rem delectus facilis rerum deserunt. Vero aperiam error molestias et at, blanditiis, ab quod quae facere sunt iusto voluptatem debitis neque atque fugit dignissimos laborum! Labore sequi accusantium, debitis vitae officiis accusamus esse quisquam volupt"
        />
      </div> */}
    </div>
  );
}

export default Services2;
