// import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
// import AboutImg from "../assets/night.jpg";
import Footer from "../components/Footer";
import Service1 from "../components/Service1";
// import Image13 from "../assets/13.jpg"

function service1() {
  return (
    <>
      <Navbar />
      {/* <Hero
        cName='hero-mid'
        heroImg={Image13}
        // title='Service'
        // text='We providing you the Services as ....'
        // buttonText='Get in Touch'
        // url='/'
        btnClass='hide'
       /> */}
       <Service1 />
       <Footer/>
    </>
  );
}

export default service1;
